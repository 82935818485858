import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"strict-transport-security":"max-age=31536000; includeSubDomains; preload","x-cache":"HIT, HIT","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","age":"23091","server":"cloudflare","date":"Fri, 11 Oct 2024 14:59:39 GMT","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","cf-ray":"8d0fb71c2936cf12-SJC","timing-allow-origin":"*","x-content-type-options":"nosniff","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21976-LGA","vary":"Accept-Encoding","x-jsd-version":"1.0.8","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","cross-origin-resource-policy":"cross-origin","x-jsd-version-type":"version","accept-ranges":"bytes","access-control-expose-headers":"*","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=WhrVUtTWkyr3bMlu2GsaHYNYtourQ%2B%2Fk%2FdhZSt8rj6dY8Zz7xCS21VXqmDNRr5jLfYSnNJZGHHim5z0gWf9K3IAsv2giJGVISDlCbveDUrbKJFyICOWa1u3MEihys5flgpo%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","access-control-allow-origin":"*","cf-cache-status":"HIT","content-type":"text/plain; charset=utf-8","alt-svc":"h3=\":443\"; ma=86400"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1728658779}